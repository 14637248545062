<template>
    <div>
        <b-card>                       
            <HousingFilter
                :searchFilter="searchFilter"
                :isSearchingHousing="isSearchingHousing"
                :isCollapsedFilters="isCollapsedFilters"
                :tagsFilter="tagsFilter"
                @get-housing="getHousing"
                @set-collapse-filter="togleCollapseFilter"
                @set-tags-filter="createTagsFilter"
                @delete-tag="deleteTag"
            />

            <HousingList
                :isSearchingHousing="isSearchingHousing"
                :isSuccessfulSearch="isSuccessfulSearch"                              
            />

        </b-card>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from "vuex"      
    import HousingFilter from "@/modules/fivesClub/components/catalogs/housing/HousingFilter"
    import HousingList from "@/modules/fivesClub/components/catalogs/housing/HousingList"
    import { makeParamsFilterHousing } from '@/helpers/fivesClubHelper'
    import { showAlertMessage } from '@/helpers/helpers'

    export default {
        components: {            
            HousingFilter,
            HousingList
        },
        async created() {
            await this.getInitialData()
        },
        data() {
            return {
                searchFilter: {
                    rentalPool: null,                    
                    selectedCondo: null,
                    selectedSubCondo: null,
                    Filtrado: null,
                    resort: null,
                    roomType: null,

                    isActive: 1, 
                    paymentType: null,
                    withOverdueBalance: false,  // con saldo vencido                 
                },
                isCollapsedFilters: false,
                isSearchingHousing: false,
                isSuccessfulSearch: false,
                tagsFilter: [],                                
            }
        },
        computed: {
            ...mapState('start', ['hotels']),      
            ...mapState("groups", ["roomsTypes"]),
            ...mapState('fivesClubCatalogs', ['housing','filter', 'isMarkedAllHousing', 'requestHousing', 'condos', 'subCondos', 'rentalPool','paymentTypes']),                
        },
        methods: {
            ...mapActions('fivesClubCatalogs', ['getInitialContentListHousing', 'fetchHousing']),
            ...mapMutations('fivesClubCatalogs',['setCondos','setSubCondos','setHousing','setRentalPool','setPaymentTypes','setIsMarkedAllHousing','setRequestHousing','removePropertyRequestHousing']),       

            ...mapMutations('start',['setHotels']),                        
            async getInitialData(){
                const { condos, subCondos, hotels, rentalPool, paymentTypes } = await this.getInitialContentListHousing();
                this.setCondos(condos)
                this.setSubCondos(subCondos)
                this.setRentalPool(rentalPool)
                this.setPaymentTypes(paymentTypes)
                if( this.hotels.length === 0 ){
                    this.setHotels(hotels)
                }
                this.createTagsFilter()                
            },
            createTagsFilter(){
                this.tagsFilter = []
                if( this.requestHousing ){
                    const { Number, IdCondo, IdSubCondo, activeResidences, resort, roomType, rentalPoolId, paymentTypeId, balanceDue } = this.requestHousing                
                    const hotel = resort ? this.hotels.find(item => item.id === resort) : null
                    const condo = IdCondo ? this.condos.find(item => item.id === IdCondo) : null                    
                    const subCondo = IdSubCondo ? this.subCondos.find(item => item.id === IdSubCondo) : null
                    const roomTypeF = roomType ? this.roomsTypes?.find(item => item.id === roomType) : null                    
                    const rentalPool = rentalPoolId ? this.rentalPool.find(item => item.id === rentalPoolId) : null                    
                    const paymentType = paymentTypeId ? this.paymentTypes.find(item => item.id === paymentTypeId) : null                    

                    //viviendas con saldo vencido
                    if(balanceDue){
                        this.tagsFilter.push( { key: 'balanceDue', name: `Viviendas con saldo vencido`, value: balanceDue, showDelete: true} )
                    }
                    //tipo de pago: rendimiento, anticipado
                    if( paymentType ){
                        this.tagsFilter.push( { key: 'paymentTypeId', name: `Tipo de pago: ${paymentType.name}`, value: paymentTypeId, showDelete: true} )
                    }
                    if( hotel ){
                        this.tagsFilter.push( { key: 'resort', name: `Resort: ${hotel.name}`, value: resort, showDelete: true} )
                    }
                    if( rentalPool ){
                        this.tagsFilter.push( { key: 'rentalPoolId', name: `Pool de rentas: ${rentalPool.name}` , value:rentalPoolId, showDelete: true} )
                    }
                    if( condo ){
                        this.tagsFilter.push( { key: 'IdCondo', name: condo.name, value: IdCondo, showDelete: true} )
                    }
                    if( subCondo ){
                        this.tagsFilter.push( { key: 'IdSubCondo', name: `Subcondominio: ${subCondo.name }`, value: IdSubCondo, showDelete: true} )
                    }
                    if(Number){
                        this.tagsFilter.push( { key: 'Number', name: `Vivienda Número: ${Number}`, value: Number, showDelete: true} )
                    }
                    if( roomType ){
                        this.tagsFilter.push( { key: 'roomType', name: `Hábitación: ${roomTypeF.name}`, value: roomType, showDelete: true} )
                    }

                    
                    let tagResidences = ''
                    let showDelete = false

                    if( !activeResidences ){
                        tagResidences = 'Todas las viviendas'                        
                    }
                    if( activeResidences == 0){
                        tagResidences = 'Viviendas inactivas'  
                        showDelete = true
                    }

                    if(activeResidences == 1){
                        tagResidences =  'Viviendas activas' 
                        showDelete = true
                    }

                    this.tagsFilter.push( { key: 'activeResidences', name: tagResidences, value: activeResidences, showDelete} )
                }
            },
            deleteTag(tagData){                  
                const tagKey =  tagData.key            
                this.tagsFilter = this.tagsFilter.filter(tag => tag.key !== tagKey )
                this.removePropertyRequestHousing(tagKey)
                this.unsetSearchFilterByTag(tagKey)
                if( tagKey === 'activeResidences'){
                    this.createTagsFilter() // para que en los nuevos tags diga 'Todas las viviendas' y no tengo la opción de borrar
                }
            },
            unsetSearchFilterByTag(tagKey){
                this.searchFilter.selectedCondo = tagKey !== 'IdCondo' ? this.searchFilter.selectedCondo: null
                this.searchFilter.selectedSubCondo = tagKey !== 'IdSubCondo' ? this.searchFilter.selectedSubCondo: null
                this.searchFilter.Filtrado = tagKey !== 'Number' ? this.searchFilter.Filtrado: null
                this.searchFilter.resort = tagKey !== 'resort' ? this.searchFilter.resort: null
                this.searchFilter.roomType = tagKey !== 'roomType' ? this.searchFilter.roomType: null
                this.searchFilter.rentalPool = tagKey !== 'rentalPoolId' ? this.searchFilter.rentalPool: null    
                this.searchFilter.paymentType = tagKey !== 'paymentTypeId' ? this.searchFilter.paymentType: null                                
                this.searchFilter.withOverdueBalance = tagKey !== 'balanceDue' ? this.searchFilter.withOverdueBalance: false                                                
                this.searchFilter.isActive = tagKey !== 'activeResidences' ? this.searchFilter.isActive: null                                                
                
            },
            togleCollapseFilter(boolean){
                this.isCollapsedFilters = boolean
            },
           
            async getHousing(data){
                this.setHousing([])                
                this.setIsMarkedAllHousing(false)                                
                this.isSearchingHousing = true
                const parametros = makeParamsFilterHousing( data )       
                this.setRequestHousing(parametros) 
                //creo los tags filtros
                this.createTagsFilter()        
                const listHousing = await this.fetchHousing(parametros)
                this.addPropertyHousingList(listHousing) ///añadir columnas a la lista de items
                this.setHousing(listHousing)                
                this.isSuccessfulSearch = true
                const toggleCollapse = listHousing.length === 0
                this.togleCollapseFilter(toggleCollapse) // cierro o mantengo abierto el collapse de filtros
                if( listHousing?.length === 0){
                    this.isSuccessfulSearch = false
                    showAlertMessage('Sin viviendas', 'InfoIcon', 'No existen viviendas para esta búsqueda', 'warning', 4000, 'bottom-right' )
                }                              
                this.isSearchingHousing = false
            },
            addPropertyHousingList(listHousing){
                listHousing.forEach(h => {
                    h.isMarked = false
                    h.owner = h.owners.find( owner => owner.relation == '1') 
                    h.coOwners = h.owners.filter( owner => owner.relation != '1') 

                })
                return listHousing
            }            
            
        },
    };
</script>