<template>
	<b-modal size="sm" id="modal-quotas-housing-massive" title="Cobro de cuotas" ok-only hide-footer no-close-on-backdrop>
		<b-overlay :show="isSavingCuota ">
			<ValidationObserver ref="modalQuotasMassive" v-slot="{ invalid }">
				<b-form @submit.prevent="handlerQuotas">
					<b-row>

						<b-col md="12" v-if="periodicity">
							<b-form-group label="Periodicidad">
								<b-badge variant="primary"> {{periodicityData.name}} </b-badge> <br>
								<p class="text-strong mt-1"> <strong> {{periodicityData.months}} </strong></p>
							</b-form-group>							
						</b-col>
									
						<b-col md="12">
							<ValidationProvider rules="required" name="Fecha de pago">
								<b-form-group label="Fecha de pago" slot-scope="{ valid, errors }">
									<b-form-input
										type="date"                  					                                  
										:state="errors[0] ? false : valid ? true : null"
										v-model="feeCuota.paymentDate"
									>
									</b-form-input>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>				
						</b-col>

						<b-col md="12">
							<b-form-group label="Tipo de pago">
								<b-badge variant="success">  {{ paymentType.name }} </b-badge>
							</b-form-group>
						</b-col>

						<b-col md="12">
							<ValidationProvider rules="" name="Notas">
								<b-form-group label="Para agregar notas">
									<b-form-textarea
										v-model="feeCuota.notes"
										placeholder="Escribe la nota"
										:maxlength="100"
										rows="2"
									>
									</b-form-textarea>
								</b-form-group>
							</ValidationProvider>
						</b-col>

					</b-row>
					
					<div class="btn-modal">
						<b-button type="submit" :disabled="invalid" variant="primary"> Guardar </b-button>
					</div>
					
				</b-form>
			</ValidationObserver> 		
		</b-overlay>
	</b-modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { showAlertMessage } from "@/helpers/helpers";
import { utils } from "@/modules/fivesClub/mixins/utils";

export default {
	mixins: [utils],
	props:{              
        periodicity: {
            type: Object,
            default: null,
        }
    },
	data(){
		return {
			feeCuota: {							
				isMassive: true,								
				notes: '',
				paymentDate: '',											
			},
			isSavingCuota: false,						
		}
	},
	computed: {
        ...mapState('fivesClubCatalogs', ['housing']),    
        ...mapState('start', ['hotels']),
        ...mapState("groups", ["roomsTypes"]),
		...mapState('auth', ['user']),

		paymentType(){
			return { id: 1, name: 'Rendimiento'}
		},        
		periodicityData(){
			const info  = { name: '', months: '', idCondo: null }
			const periods = { ME : 'Mensual', BI : 'Bimestral', TR : 'Trimestral', CU : 'Cuatrimestral', SE : 'Semestral', }
			
			if(this.periodicity){

				let resultMonths = ''
				if( this.periodicity.months.length > 0 ){
					const months = this.periodicity.months
					const ultimoRegistro = months.pop() // Extraer y almacenar el último registro
					resultMonths = months.join(", ") + " y " + ultimoRegistro
				}

				info.name = periods[this.periodicity.shorthand]
				info.months = resultMonths
				info.idCondo = this.periodicity.idCondo
			}
			return info
		},                              
    },
	methods:{
		
		...mapActions('fivesClubCatalogs', ['saveCondoFeeCalculation']),		      
        ...mapMutations('fivesClubCatalogs',['setAllMarkedHousing','setIsMarkedAllHousing']),
		
		async handlerQuotas(){
			this.isSavingCuota = true
            const isMarkeds = this.housing.filter( h => h.isMarked ) // filtro los que están marked
            const ids = isMarkeds.map( h => h.id) // genero arreglo de ids
            
			const payload  = {
				...this.feeCuota,
				ids,
				isPaid: 1,
				isMassive: 1,
				idCondoFee: this.periodicityData.idCondo,				
				userId: this.user.idUser,
			}					
			// console.log(payload)
			// return
			const { status, message } = await this.saveCondoFeeCalculation(payload)

            if (status) {
                showAlertMessage('Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
                Object.assign(this.$data, this.$options.data());
                this.$refs.modalQuotasMassive.reset(); //reset form      
				
				this.setAllMarkedHousing({ isMarked: false }) // el ckeckbox del top de la tabla de viviendas              
            	this.setIsMarkedAllHousing(false) // desmarco todas las viviendas marcadas   
            }
			
			this.isSavingCuota = false
            this.$root.$emit("bv::hide::modal", 'modal-quotas-housing-massive')// cierro modal


		}
	}
}
</script>

<style scoped>
.btn-modal{
	display: flex;
	justify-content: flex-end;
}
</style>