<template>

    <b-overlay class="filter-housing" :show="false">
        <div class="head-filter">

            <div class="tags-head">
                <div class="tag" v-for="tag in tagsFilter" :key="tag.key" >
                    <span>{{tag.name}}</span>
                    <button class="delete-button" @click="$emit('delete-tag', tag)" v-show="tag.showDelete">
                        <feather-icon icon="XIcon" size="18"/>
                    </button>
                </div>
            </div>

            <div class="buttons-filter">
                <b-button variant="primary" @click="setCollapseFilter(!isCollapsedFilters)"  class="mr-1"  >
                    Filtros <feather-icon :icon="isCollapsedFilters ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
                </b-button>

                <!-- <b-button
                    :disabled="isSearchingHousing"
                    v-show="!isCollapsedFilters && requestHousing"
                    @click="resetHard" variant="warning" class="mr-1"> Reset
                </b-button>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-show="!isCollapsedFilters && requestHousing"
                    @click="submitFormFromButton"
                    :disabled="isSearchingHousing"
                >
                    Buscar
                </b-button>     -->

            </div>
        </div>


        <b-collapse id="accordeon-filters" v-model="isCollapsedFilters" >
            <validation-observer tag="form" v-slot="{ invalid }" ref="filterHousing" >
                <b-form @submit.prevent="searchHousing" ref="formFilter" >

                    <b-row>

                        <b-col md="3">
                            <validation-provider name="resort">
                                <b-form-group slot-scope="{ valid, errors }" label="Resort">
                                <b-form-select
                                    :state="errors[0] ? false : valid ? true : null"
                                    v-model="searchFilter.resort"
                                    :clearable="false"
                                    @change="handlerResort"
                                    :disabled="isSearchingHousing"
                                >
                                    <option :value="null">Seleccione una opción</option>
                                    <option v-for="h in hotels" :key="h.id" :value="h.id"> {{ h.name }}</option>
                                </b-form-select>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="3">
                            <validation-provider name="vivienda" rules="">
                                <b-form-group slot-scope="{ valid, errors }" label="Vivienda" >
                                    <b-form-input
                                        type="number"
                                        :state="errors[0] ? false : valid ? true : null"
                                        v-model="searchFilter.Filtrado"
                                        placeholder="Número de vivienda"
                                        :disabled="isSearchingHousing"
                                    />

                                    <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="3">
                            <validation-provider name="habitacion">
                                <b-form-group slot-scope="{ valid, errors }" label="Tipo de habitación">
                                    <b-form-select
                                        :state="errors[0] ? false : valid ? true : null"
                                        v-model="searchFilter.roomType"
                                        :clearable="false"
                                        :disabled="isSearchingHousing"
                                    >
                                        <option :value="null">Seleccione una opción</option>
                                        <option v-for="h in roomsTypes" :key="h.id" :value="h.id"> {{ h.name }} </option>
                                    </b-form-select>
                                    <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="3">
                            <validation-provider name="pool de renta" rules="">
                                <b-form-group slot-scope="{ valid, errors }" label="Pool de rentas" >
                                <!-- @input="resetFilter('soft')" -->
                                    <b-form-select v-model="searchFilter.rentalPool" :disabled="isSearchingHousing">
                                        <option disabled :value="null">Seleccione una opción</option>
                                        <option v-for="pool in rentalPoolByHotel" :key="pool.id" :value="pool.id" > {{ pool.name }} </option>
                                    </b-form-select>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="3">
                            <validation-provider name="status de la vivienda" rules="">
                                <b-form-group slot-scope="{ valid, errors }" label="Estado de la vivienda" >
                                <!-- @input="resetFilter('soft')" -->
                                    <b-form-select v-model="searchFilter.isActive" :disabled="isSearchingHousing">
                                        <option v-for="status in housingStatus" :key="status.id" :value="status.id" > {{ status.name }} </option>
                                    </b-form-select>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="3">
                            <validation-provider name="Tipo de pago" rules="">
                                <b-form-group slot-scope="{ valid, errors }" label="Tipo de pagos" >
                                <!-- @input="resetFilter('soft')" -->
                                    <b-form-select v-model="searchFilter.paymentType" :disabled="isSearchingHousing">
                                        <option disabled :value="null">Seleccione una opción</option>
                                        <option v-for="pType in paymentTypes" :key="pType.id" :value="pType.id" > {{ pType.name }} </option>
                                    </b-form-select>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="3" align-self="center">
                            <validation-provider name="Tipo de pago" rules="">

                                <b-form-group slot-scope="{ valid, errors }" class="mt-2" label="" >
                                    <b-form-checkbox
                                        v-model="searchFilter.withOverdueBalance"
                                        :value="true"
                                        class="custom-control-success"
                                        :disabled="isSearchingHousing"
                                    >   Viviendas con saldo vencido
                                    </b-form-checkbox>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                    </b-row>

                    <div class="btn-submit">
                        <b-button @click="resetHard" variant="warning" class="mr-1"> Reset</b-button>

                        <b-button
                            ref="btnFilter"
                            type="submit"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            :disabled="invalid || isSearchingHousing"
                        >
                            Buscar
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </b-collapse>

    </b-overlay>

</template>

<script>
    import { mapMutations, mapState, mapActions } from 'vuex'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import Ripple from 'vue-ripple-directive'
    import { makeParamsFilterHousing } from '@/helpers/fivesClubHelper'

    export default {
        directives: { Ripple, },
        props:{
            searchFilter: {
                type: Object,
                required: true,
            },
            isSearchingHousing: {
                type: Boolean,
                required: true,
            },
            isCollapsedFilters: {
                type: Boolean,
                required: true,
            },
            tagsFilter:{
                type: Array,
                required: true,
            }
        },
        components:{
            AppCollapse,
            AppCollapseItem,
        },
        created(){
            this.setInitialValuesFilter()
            this.openFiltersIfNoHousingList()
        },
        data() {
            return {
                Filtros: [
                    {   id: 'resort', name: 'Resort'},
                    {   id: 'condo', name: 'Condominio'},
                ],
                housingStatus: [
                    {   id: null, name: 'Seleccione una opción'},
                    {   id: 0, name: 'Inactiva'},
                    {   id: 1, name: 'Activa'},
                ],
            }
        },
        computed:{
            ...mapState('fivesClubCatalogs', ['filter', 'condos', 'subCondos', 'housing', 'paymentTypes', 'rentalPool','requestHousing']),
            ...mapState("groups", ["roomsTypes"]),
            ...mapState('start', ['hotels']),
            condosByHotel(){
                //mejorar esto
                let condos = []
                const idHotel = this.searchFilter.resort

                if( idHotel ){
                    condos = this.condos.filter( condo => condo.resorts === idHotel )
                }

                if(!idHotel){
                    condos = this.condos
                }
                return  condos
            },
            rentalPoolByHotel(){
                let rentalPool = []
                const idHotel = this.searchFilter.resort

                if( idHotel ){
                    rentalPool = this.rentalPool.filter( rntP => rntP.resortid === idHotel )
                }

                if(!idHotel){
                    rentalPool = this.rentalPool
                }
                return  rentalPool
            },
            subcondos(){
                const idCondoSelected = this.searchFilter.selectedCondo ? this.searchFilter.selectedCondo : null
                return idCondoSelected ? this.subCondos.filter( c => c.condos === idCondoSelected) : []
            }
        },
        methods: {
            ...mapActions('groups', ['getRoomTypesHousing']),
            ...mapMutations('groups', ['setRoomsTypes']),
            ...mapMutations('fivesClubCatalogs',['setHousing','setIsMarkedAllHousing','setRequestHousing']),
            setCollapseFilter(boolean){
                this.$emit('set-collapse-filter', boolean)
            },
            openFiltersIfNoHousingList(){
                if( !this.housing.length ){
                    this.setCollapseFilter(true) // abro el collapse si no hay listado de viviendas
                }
            },
            async handlerResort(idResort){

                this.searchFilter.roomType = null //quito valor previo que tenga
                this.searchFilter.selectedCondo = null //quito valor previo que tenga
                this.searchFilter.selectedSubCondo = null //quito valor previo que tenga
                this.searchFilter.rentalPool = null //quito valor previo que tenga


                if(idResort){
                    const roomsTypes = await this.getRoomTypesHousing(idResort)
                    this.setRoomsTypes(roomsTypes)
                }
                if(!idResort){
                    this.setRoomsTypes([])
                }

                this.$refs.filterHousing.reset()

            },
            async setInitialValuesFilter(){
                if( this.requestHousing ){
                    const { Number, IdCondo, IdSubCondo, activeResidences, resort, roomType, rentalPoolId, paymentTypeId, balanceDue } = this.requestHousing

                    this.searchFilter.rentalPool = rentalPoolId || null
                    this.searchFilter.selectedCondo = IdCondo || ''
                    this.searchFilter.selectedSubCondo = IdSubCondo || ''
                    this.searchFilter.Filtrado = Number || null
                    this.searchFilter.resort = resort || null
                    this.searchFilter.roomType = roomType || null
                    this.searchFilter.isActive = ( typeof activeResidences !== 'undefined') ? activeResidences : null
                    this.searchFilter.paymentType = paymentTypeId || null
                    this.searchFilter.withOverdueBalance = balanceDue || false  // con saldo vencido

                    if(resort){
                        const roomsTypes = await this.getRoomTypesHousing(resort)
                        this.setRoomsTypes(roomsTypes)
                    }
                }
            },

            resetFiltersSoft(){
                this.searchFilter.selectedCondo = null
                this.searchFilter.selectedSubCondo = null
                this.searchFilter.Filtrado = null
                this.searchFilter.resort = null
                this.searchFilter.roomType = null

                this.$refs.filterHousing.reset()
                const parametros = makeParamsFilterHousing(  this.payloadSearchHousing() )   // actualizo lo que hay en el state requestHousing
                this.setRequestHousing(parametros)
            },
            resetHard(){
                this.resetFiltersSoft()
                this.searchFilter.isActive = '1',
                this.searchFilter.rentalPool = null
                this.searchFilter.paymentType = null
                this.searchFilter.withOverdueBalance = false

                this.setHousing([])
                this.setCollapseFilter(true) // abro el collapse
                this.setIsMarkedAllHousing(false)
                this.setRequestHousing(null)
                this.$emit('set-tags-filter')
            },
            payloadSearchHousing(){
                return {
                    IdCondo: this.searchFilter.selectedCondo,
                    IdSubCondo: this.searchFilter.selectedSubCondo,
                    Number: this.searchFilter.Filtrado,
                    activeResidences: this.searchFilter.isActive,
                    resort: this.searchFilter.resort,
                    roomType: this.searchFilter.roomType,
                    rentalPoolId: this.searchFilter.rentalPool,
                    paymentTypeId: this.searchFilter.paymentType,
                    balanceDue: this.searchFilter.withOverdueBalance,
                }
            },
            submitFormFromButton() {
                this.searchHousing()
            },
            searchHousing(){
                this.$emit('get-housing', this.payloadSearchHousing() ) //armo el payload de busqueda
            },
            handlerCondo(idCondo){
                this.searchFilter.selectedCondo = idCondo
                this.searchFilter.selectedSubCondo = null
            },

        }
    };
</script>
<style scoped>

.filter-housing{
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 1rem;
    background-color: #f3f2f7;
}

.head-filter{
    display: flex;
    justify-content:space-between;
    align-items: center;
}

.tags-head{
    display: flex;
    margin-bottom: 1rem;
    /* justify-content: flex-end; */
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.buttons-filter{
    display: flex;
    justify-content: flex-end;
}



.btn-submit{
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-end;
    align-items: center;
}

.tag {
  display: inline-flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 2px 2px rgba(15, 15, 15, 0.1);
  color: black;
  border-radius: 4px;
  padding: 4px 8px;
  /* margin-right: 8px; */
}

.tag span {
  margin-right: 4px;
  font-size: .95rem;
}

.delete-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #c21b1b;
}

</style>
